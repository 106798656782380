import React, { useEffect } from "react"

export default () => {
  useEffect(() => {
    window.location.href =
      "https://docs.partnr.ai/docs/apis/Brazil-Companies-API.yaml/paths/~1companies/get"
  }, [])

  return <div></div>
}
